/* Add these at the top of the file */
html, body {
  margin: 0;
  padding: 0;
  background-color: #000;
  min-height: 100vh;
}

.gallery-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 64px;
}

.gallery-title {
  /*
    font-family: 'Yusei Magic', sans-serif;
    font-family: 'Zen Antique', serif;
    font-family: 'New Tegomin', serif;
  */
  font-family: 'Shippori Antique', sans-serif;
  font-size: 4rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.3);
  letter-spacing: 0.1em;
  text-align: center;
  transition: text-shadow 0.3s ease;
}

.gallery-title:hover {
  text-shadow: 
    2px 2px 8px rgba(255, 0, 0, 0.4),
    -2px -2px 8px rgba(255, 0, 0, 0.4);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.1);
}

.gallery-image:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 6px 12px rgba(255, 0, 0, 0.2);
}

/* Make it responsive */
@media (max-width: 900px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gallery-title {
    font-size: 3rem;
  }
}

@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: 1fr;
  }
  
  .gallery-title {
    font-size: 2.5rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.modal-content {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
  border: 1px solid rgba(255, 0, 0, 0.2);
  transform: scale(0.95);
  opacity: 0;
  animation: modalIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close:hover {
  background-color: rgba(255, 0, 0, 0.2);
  transform: rotate(90deg);
}

.modal-title {
  color: #fff;
  font-family: 'Shippori Antique', sans-serif;
  margin-bottom: 20px;
  text-align: center;
}

.modal-image {
  max-width: 100%;
  max-height: 70vh;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
}

.modal-description {
  color: #fff;
  margin-top: 20px;
  text-align: center;
  font-family: 'Shippori Antique', sans-serif;
}